import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { Button, Layout, Text } from '../components'
import { TEXTS } from '../constants'

export default () => {
    const { text, info, map } = useStaticQuery(graphql`
      {
        text: contentfulText(tid: {eq: "cherepovets"}) {
          title
          content {
            json
          }
        },
        info: contentfulAsset(title: {eq: "Череповец инфографика"}) {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
        map: contentfulAsset(title: {eq: "Череповец карта"}) {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        },
      }`)
    return (
        <Layout header={text.title} pageTitle={text.title}>
            <Text content={text.content.json}/>
            <Button to="cherepovets-details" text={TEXTS.details} mode="single"/>
            <Img fluid={info.localFile.childImageSharp.fluid} alt={TEXTS.cherepovetsInfo}/>
            <Img fluid={map.localFile.childImageSharp.fluid} alt={TEXTS.cherepovetsMap}/>
        </Layout>
    )
}
